body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.markDownContainer  table {
  margin-bottom: 1em;
}
.markDownContainer  th {
  border-bottom: 2px solid black;
  background-color: #FAFBFD;
  padding: 0.5em;
  text-align: start;
}
.markDownContainer  td {
  text-align: start;
  padding: 0.5em;
}